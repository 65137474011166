import React, { useEffect } from "react";
import { Col, Row, Container } from "react-bootstrap";
import FAQ from "../components/faq";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { SIGNUP_URL } from "../util/constants";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import Testimonial from "../components/testimonial";
import Skel from "../components/skel";

const BreweryMenus = () => {
  const data = useStaticQuery(graphql`
    query {
      breweryMenusPromo: file(
        relativePath: { eq: "mockups/brewery-menus.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 95) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }

      wallTv3: file(relativePath: { eq: "mockups/wall-tv-3.png" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 95) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }

      phoneAndLaptop: file(
        relativePath: { eq: "mockups/phone-and-laptop-01.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 95) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }

      printMenuTable: file(
        relativePath: { eq: "mockups/print-menu-table-cropped.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 95) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }

      moonrakerLogo: file(
        relativePath: { eq: "customer-logos/moonraker.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 95) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }

      bareArmsLogo: file(relativePath: { eq: "customer-logos/bare-arms.png" }) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 95) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }

      finalGravityLogo: file(
        relativePath: { eq: "customer-logos/final-gravity.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 95) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }

      pubDogLogo: file(relativePath: { eq: "customer-logos/pubdog.png" }) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 95) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `);

  return (
    <Skel dark noHeader>
      <SEO
        title={"TV Menus Boards for Breweries and Taprooms"}
        description={
          "Design and launch a digital TV menu in minutes. Supports print, QR, and embed too."
        }
      />
      <section className="section" style={{ paddingTop: 30, paddingBottom: 0 }}>
        <Container>
          {/* <Row>
          <Col md={3}>
            <Link to="/integrations">&laquo; Integrations</Link>
          </Col>
        </Row> */}
          <Row>
            <Col
              className="justify-content-center text-center"
              md={{ span: 8, offset: 2 }}
            >
              <h1>Instant TV Menus for Breweries &amp; Taprooms</h1>
              <p className="lead">
                Design and launch a TV menu for your brewery, taproom, or tasting
                room with Taplist.io&mdash;in just a few clicks.
              </p>
            </Col>
          </Row>
          <Row>
            <Col className="justify-content-center text-center">
              <Img
                className="max-width-auto"
                fluid={data.breweryMenusPromo.childImageSharp.fluid}
                loading="eager"
                fadeIn={false}
                alt="A brewer menu shown on a full-screen TV, with a laptop showing the Taplist.io management dashboard."
              />
            </Col>
          </Row>
        </Container>
      </section>
      <section className="gray-bg overflow-hidden" style={{ padding: 30 }}>
        <Container>
          <Row>
            <Col className="justify-content-center text-center">
              <p class="lead">
                Try out the management dashboard now, no credit card required.
              </p>
              <a
                className="m-btn m-btn-theme2nd m-btn-radius"
                href={SIGNUP_URL}
              >
                Get Started
              </a>
            </Col>
          </Row>
        </Container>
      </section>
      <section id="overview" className="section effect-section">
        <Container>
          <Row className="md-m-25px-b m-45px-b justify-content-center text-center">
            <Col lg={8}>
              <h3 className="h1 m-15px-b">
                {/* Beautiful, Always-Accurate Menus */}
                Save Time. Look Great. Sell More.
              </h3>
              <p className="m-0px font-2">
                Keep your menus{" "}
                <b>
                  up-to-date <i>everywhere</i>
                </b>{" "}
                by using Taplist.io. Add, remove, and change products from
                anywhere &amp; publish them instantly.
              </p>
            </Col>
          </Row>
          <Row className="md-m-25px-b m-45px-b justify-content-center">
            <Col lg={4}>
              <div className="border-radius-10 box-shadow-hover hover-top white-bg p-20px-tb">
                <div className="p-30px">
                  <h3 className="m-15px-b">TV Menu Boards</h3>
                  <Img
                    className="max-width-auto"
                    fluid={data.wallTv3.childImageSharp.fluid}
                    loading="eager"
                    alt="A successfully paired Taplist.io TV device"
                  />
                  <p>
                    Link one or more TVs to your account, customize the
                    appearance, and watch your menus update instantly.
                  </p>
                </div>
              </div>
            </Col>
            <Col lg={4}>
              <div className="border-radius-10 box-shadow-hover hover-top white-bg p-20px-tb">
                <div className="p-30px">
                  <h3 className="m-15px-b">Web/QR Menus</h3>
                  <Img
                    className="max-width-auto"
                    fluid={data.phoneAndLaptop.childImageSharp.fluid}
                    loading="eager"
                    alt="A beverage menu web page"
                  />
                  <p>
                    Get an instant QR code beer and beverage menu, and embed
                    your menu on any web page.
                  </p>
                </div>
              </div>
            </Col>
            <Col lg={4}>
              <div className="border-radius-10 box-shadow-hover hover-top white-bg p-20px-tb">
                <div className="p-30px">
                  <h3 className="m-15px-b">Printed Menus</h3>
                  <Img
                    className="max-width-auto"
                    fluid={data.printMenuTable.childImageSharp.fluid}
                    loading="eager"
                    alt="A printed Taplist.io menu"
                  />
                  <p>
                    Automatically generate and download custom, printable PDFs
                    of your food and beverage menus.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="font-2 font-w-600  justify-content-center text-center">
            <Col lg={8}>
              <p>
                <b>Food and Beverages together:</b> Our <em>Pro</em> menu editor
                supports listing food and any other product type along with
                beverages, on a single menu. No more frustrated customers
                switching between two different menus.
              </p>
            </Col>
          </Row>
        </Container>
      </section>

      <section id="testimonials" className="section gray-bg">
        <Row className="md-m-25px-b m-45px-b justify-content-center text-center">
          <Col lg={8}>
            <h3 className="h1 m-15px-b">Trusted By Breweries Everywhere</h3>
            <p className="m-0px font-2">
              You'll be in good company: See why <b>over 1,000 customers</b>{" "}
              have chosen or switched to Taplist.io.
            </p>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Testimonial
            customerName={<>Pub Dog Pizza &amp; Drafthouse</>}
            customerLocation={"Columbia, MD"}
            customerLogo={data.pubDogLogo}
            description={
              <div>
                <p>
                  Taplist.io was very easy to set up and we had our first menu
                  created in minutes. A great product for a great price with
                  world-class support.
                </p>
              </div>
            }
          />
          <Testimonial
            customerName={"Moonraker Brewing Company"}
            customerLocation={"Auburn, CA"}
            customerLogo={data.moonrakerLogo}
            description={
              <div>
                <p>
                  We've tried other software and self-made templates and
                  Taplist.io is definitely superior. It has made ordering a
                  breeze across the board.
                </p>
              </div>
            }
          />
          <Testimonial
            customerName={"Bare Arms Brewing"}
            customerLocation={"Waco, TX"}
            customerLogo={data.bareArmsLogo}
            description={
              <div>
                <p>
                  We've been using Taplist.io for over 3 years now and our staff
                  and customers absolutely love it. It's easy for our staff to
                  make menu changes as our beers change.
                </p>
              </div>
            }
          />
        </Row>
        <Row className="font-2 font-w-600  justify-content-center text-center">
          <Col lg={8}>
            <p>
              <b>Our risk-free guarantee:</b> We're a friendly, family-run
              team&mdash;and we stand behind our product. All of our plans come
              with our money-back guarantee. If you're not happy, we'll make it
              right.
            </p>
            {/* <p>
                <small>
                Since 2016, fewer than 1% of our customers have <em>ever</em>{" "}
                experienced a service issue or asked for a refund.
                </small>
              </p> */}
          </Col>
        </Row>
      </section>

      <section className="section" style={{ paddingBottom: 32 }}>
        <Container>
          <Row className="md-m-25px-b m-45px-b justify-content-center text-center">
            <Col lg={8}>
              <h3 className="h1 m-15px-b">The Taplist.io Difference</h3>
            </Col>
          </Row>

          <Row className="md-m-25px-b m-45px-b justify-content-center">
            <Col lg={6}>
              <div className="border-radius-10 box-shadow-hover hover-top white-bg p-20px-tb">
                <div className="p-30px">
                  <h3 className="h1 m-15px-b">We Don't...</h3>
                  <ul className="list-type-02">
                    <li>
                      <i className="fas fa-times" style={{ color: "red" }}></i>{" "}
                      <b>Clutter your menus with crap.</b> We're not promoting a
                      social network. We're here to make you look good and sell
                      more. Your menus, your brand, that's our focus.
                    </li>
                    <li>
                      <i className="fas fa-times" style={{ color: "red" }}></i>{" "}
                      <b>Surprise you with price hikes.</b> We are proud to say
                      we've <b>never raised prices</b> on our customers for the
                      same product.
                    </li>
                    <li>
                      <i className="fas fa-times" style={{ color: "red" }}></i>{" "}
                      <b>Make life difficult.</b> We hear it all the time:
                      Front-of-house staff tell us managing and updating their
                      menu with Taplist.io is the{" "}
                      <b>easiest, most delightful</b> part of their day.
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
            <Col lg={6}>
              <div class="border-radius-10 box-shadow-hover hover-top white-bg p-20px-tb">
                <div class="p-30px">
                  <h3 className="h1 m-15px-b">But We're Known For...</h3>
                  <ul className="list-type-02">
                    <li>
                      <i className="fas fa-check"></i>{" "}
                      <b>Constantly innovating.</b> We launch valuable,
                      time-saving improvements all the time. There's a reason
                      the "big guys" are targeting us, and new imitators are
                      copying us.
                    </li>
                    <li>
                      <i className="fas fa-check"></i>{" "}
                      <b>Peerless ease of use and reliability.</b> Most of our
                      customers get going and have their staff trained in under
                      an hour. And we're built on{" "}
                      <a href="https://status.taplist.io/" target="_new">
                        rock solid
                      </a>{" "}
                      infrastructure that stays online.
                    </li>
                    <li>
                      <i className="fas fa-check"></i>{" "}
                      <b>Responsive, friendly support.</b> Our customers love us
                      for always listening. And if something isn't right, we
                      make it right.
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="md-m-25px-b m-45px-b justify-content-center text-center">
            <Col lg={8}>
              <p className="m-0px font-2">
                You can count on us. We've been <b>in business since 2018</b>{" "}
                and we've had a simple mission ever since:{" "}
                <b>
                  Be the easiest, most delightful part of our customer's day
                </b>
                .
              </p>
            </Col>
          </Row>
        </Container>
      </section>

      {/* <section className="section" style={{ paddingTop: 32, paddingBottom: 32}}>
        <Container>
          <Row>
            <Col md={3}>
              <h2>FAQ</h2>
            </Col>
            <Col md={9}>
              <FAQ
                faqItems={[
                  {
                    question: "Does this integration cost extra?",
                    answer: (
                      <>
                        No, this integration is currently included at no extra
                        cost on our <b>Pro</b> plan. GoTab does not charge extra
                        for this integration.
                      </>
                    ),
                  },
                  {
                    question: "Does Taplist.io modify my GoTab data?",
                    answer: (
                      <>
                        No, the integration does not change any data on your
                        GoTab account. You can think of it as "one-way sync": we
                        read your menu data, but we never alter it.
                      </>
                    ),
                  },
                  {
                    question: "How many TVs can I install?",
                    answer: (
                      <>
                        <p>
                          There's no limit! Taplist.io supports managing as many
                          TVs as you like. Many of our customers use multiple
                          TVs. You can configure TVs to all show the same menu,
                          or show different things on different TVs.
                        </p>
                        <p>
                          In some cases we charge an additional monthly fee for
                          additional activated TVs. See our transparent pricing
                          page for detail.
                        </p>
                      </>
                    ),
                  },
                  {
                    question: "I have an additional question about this.",
                    answer: (
                      <>
                        We'd love to hear from you! Please send a note to{" "}
                        <b>support@taplist.io</b> and a friendly human will get
                        right back to you.
                      </>
                    ),
                  },
                ]}
              />
            </Col>
          </Row>
        </Container>
      </section> */}

      <section className="overflow-hidden gray-bg" style={{ padding: 30 }}>
        <Container>
          <Row>
            <Col className="justify-content-center text-center">
              <p class="lead">
                See what your menu would look like in just a few clicks, no
                credit card required.
              </p>
              <a
                className="m-btn m-btn-theme2nd m-btn-radius"
                href={SIGNUP_URL}
              >
                Get Started
              </a>
            </Col>
          </Row>
        </Container>
      </section>
    </Skel>
  );
};

export default BreweryMenus;
